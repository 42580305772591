<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab :to="{ name: 'rolesPermissions', params: { setting } }">
                Roles Permissions
            </v-tab>
            <v-tab
                :to="{ name: 'rolesPermissionsDetails', params: { setting } }"
            >
                Details
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title" v-if="$vuetify.breakpoint.smAndDown">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'CompanySettings',

    props: {
        setting: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        onResize() {
            let titleHeight = 0
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            if (this.$refs.title) {
                titleHeight = this.$refs.title.clientHeight
            }
            this.height = containerHeight - tabsHeight - titleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
    },
}
</script>
